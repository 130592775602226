<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    label: {
      type: String
    },
    chartData: {
      type: Array,
    },
    options: {
      type: Object,
    }
  },
  methods: {
    random_rgba() {
      var o = Math.round, r = Math.random, s = 255;
      return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
    }
  },
  mounted () {
    const dates = this.chartData.map(d => d.date)
    const totals = this.chartData.map( d=> d.value)
    const listRandomRGB = []
    
    for(var i = 0; i <this.chartData.length; i ++){
      listRandomRGB.push(this.random_rgba())
    }


    this.renderChart({
      labels: dates,
      datasets: [{
        label: this.label,
        data: totals,
        backgroundColor: listRandomRGB,
      }]
    },
    this.options
    )

  }
}
</script>
